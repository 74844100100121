<template>
  <b-row class="justify-content-center">
    <b-col md="12">
      <VeeObserver ref="form" slim>
        <VeeProvider name="title" rules="required" v-slot="{ errors }">
          <base-input
            class="input-group-alternative"
            placeholder="Name"
            v-model="payload.name"
            :error="errors[0]"
          />
        </VeeProvider>
        <b-form-group label="Description">
          <ckeditor
            v-model="payload.description"
            name="about"
            :config="{ removeButtons: removeButtons }"
          ></ckeditor>
        </b-form-group>
        <VeeProvider name="image" rules="length:1" v-slot="{ errors }">
          <b-form-group label="Image(Trademark)">
            <FileUpload v-model="payload.image" ref="image" />
            <small class="text-danger" v-if="errors.length">
              {{ errors[0] }}
            </small>
          </b-form-group>
        </VeeProvider>
        <div class="text-center">
          <base-button
            variant="primary"
            type="submit"
            class="my-4"
            @click="isEdit ? update() : create()"
            :loading="loading"
          >
            <i class="fa fa-save"></i> {{ isEdit ? "Update" : "Create" }}
          </base-button>
        </div>
      </VeeObserver>
    </b-col>
  </b-row>
</template>
<script>
import SitePagesService from "features/settings/services/SitePagesService";

import { resource, removeButtons } from "helpers";

export default {
  name: "FormPages",
  data: () => ({
    loading: false,
    urlMode: true,
    payload: {
      name: "",
      url: "",
      image: [],
      description: "",
    },
    removeButtons,
    selectCategories: [],
  }),
  computed: {
    isEdit() {
      return this.$route.name === "pages-edit";
    },
  },
  mounted() {
    if (this.isEdit) this.getData();
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        const { data } = await SitePagesService.show(this.$route.params.id);
        this.payload.name = data.name;
        this.payload.description = data.description;
        this.payload.image = [resource(data.image)];
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Failed to get registration data",
        });
      } finally {
        this.loading = false;
      }
    },
    async create() {
      try {
        if (await this.$refs.form.validate()) {
          this.loading = true;
          const { name, image, description } = this.payload;
          const payload = {
            name,
            image: {
              type: image[0]?.ext,
              value: image[0]?.item,
            },
            description,
          };
          await SitePagesService.create(payload);
          this.$swal({
            icon: "success",
            title: "Registration performed successfully",
          }).then(() => this.$router.push("/settings/pages"));
        }
      } catch (e) {
        console.log(e);
        this.$swal({
          icon: "error",
          title: "Trademark registration failed",
          text: "Check the data entered and try again",
        });
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        if (await this.$refs.form.validate()) {
          this.loading = true;
          const { name, image, description } = this.payload;
          const payload = { name, description };
          if (image[0]?.constructor.name === "Object") {
            payload.image = {
              type: image[0].ext,
              value: image[0].item,
            };
          }
          await SitePagesService.update(payload, this.$route.params.id);
          this.$swal({
            icon: "success",
            title: "Record updated successfully",
          }).then(() => this.$router.push("/settings/pages"));
        }
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Update failed",
          text: "Verify the given informations and try again",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
